import { takeEvery, put } from 'redux-saga/effects'
import { LOGIN, SET_LOGIN_DATA } from '../constants/constant';
import env from '../../env';

function* loginUser(action) {
  const url = `${env.BASE_URL}`;
  console.log('url',url);
  let data = yield fetch(url + '/api/v1/login/', {
    method: 'PUT', // or 'PUT'
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(action.data),
  });
  data = yield data.json();
  console.warn("logged in user data", data)
  yield put({ type: SET_LOGIN_DATA, data })
}

function* loginSaga(cred) {
  yield takeEvery(LOGIN, loginUser)
}

export default loginSaga;
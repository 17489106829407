import React from "react";
import { Navigate, Route } from "react-router-dom";
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ user, children }) => {
    // let isAuthenticated = localStorage.getItem("isAuthenticated");
    let { data } = useSelector((state) => state.auth);
    let userData = data;

    if (userData?.role) {
        console.log('children',children);
        return children;
    }
    return <Navigate to="/login" replace />;

};

export default ProtectedRoute;
// import React from "react";
// import { Navigate, Route, useLocation } from "react-router-dom";

// const ProtectedRoute = ({ user, children }) => {
//     let isAuthenticated = localStorage.getItem("isAuthenticated");
//     let userType = localStorage.getItem("userType");
//     const location = useLocation();
//     console.log('Requested Path:', location.pathname);

//     if (isAuthenticated == 1) {
//         if(location.pathname == '/dashboard' || location.pathname == '/admin/dashboard') {
//             if(userType == 1) {
//                 console.log('admindashboard',userType);
//                 return <Navigate to="/admin/dashboard" replace />;
//             } else {
//                 console.log('dashboard',userType);
//                 return <Navigate to="/dashboard" replace />;
//             }
//         } else {
//             return children;
//         }
//     }
//     return <Navigate to="/login" replace />;
// };

// export default ProtectedRoute;

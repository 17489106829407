import { combineReducers } from 'redux'
import { auth } from './loginReducer'
import { logout } from './logoutReducer'
import { sidebar } from './sidebarReducer';
import { month } from './monthReducer';

export default combineReducers({
    auth,
    logout,
    sidebar,
    month
})
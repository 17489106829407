const months = [
    "january", "february", "march", "april", "may", "june",
    "july", "august", "september", "october", "november", "december"
];

const initialState = {
    selectedMonth: months[new Date().getMonth()],
};

export const month = (state = initialState, { type, ...rest }) => {    
    switch (type) {
        case 'set':            
            return { ...state, ...rest }
        default:
            return state
    }
}
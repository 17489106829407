const initialState = {
    sidebarShow: true,
}

export const sidebar = (state = initialState, { type, ...rest }) => {    
    switch (type) {
        case 'set':            
            return { ...state, ...rest }
        default:
            return state
    }
}